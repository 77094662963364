<template>
    <div :class="isPCH5Text !== 'PC' ? 'ExternalUserInfoWrap' : 'ExternalUserInfoWrap_PC'" v-loading="loading">
        <div :class="isPCH5Text !== 'PC' ? 'Shop_ApprovalInfoList_bottom' : 'Shop_ApprovalInfoList_bottom_PC'">
            <div style="width: 50%;text-align: center;border-right: 1px solid #ccc;box-sizing: border-box;"
                @click="$router.push('./Shop_ApprovalInfoList')">
                <i class="el-icon-tickets" style="font-size: 30px;"></i>
            </div>
            <div style="width: 50%;text-align: center;">
                <i class="el-icon-user-solid" style="font-size: 30px;color: #D1100B;"></i>
            </div>
        </div>
        <!-- <h2>个人中心</h2> -->

        <div class="ExternalUserInfo">

            <el-card style="width: 100%;height: calc(100vh - 80px);overflow-y: auto;">
                <el-form :model="userInfo" label-width="60px" label-position="right" size="small">
                    <el-form-item label="头像">
                        <div style="display: flex;align-items: center;justify-content: center;">
                            <el-avatar :size="100" :src="userInfo.Icon" v-if="userInfo.Icon"></el-avatar>
                            <el-avatar v-else :size="100" icon="el-icon-user-solid"></el-avatar>
                            <el-upload style="margin-left: 20px"
                                :action="$rootUrl + '/Base_Manage/Upload/ExternalUserIconOssUploadFileByForm'"
                                :headers="{ Authorization: 'Bearer ' + TokenCache.getToken(), }"
                                :on-success="handleSuccess" :before-upload="beforeAvatarUpload" :show-file-list="false">
                                <el-button size="mini" type="primary" plain>更换头像
                                </el-button>
                            </el-upload>
                        </div>

                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input v-model="userInfo.RealName"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="userInfo.Phone" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input v-model="userInfo.Email"></el-input>
                    </el-form-item>
                    <el-form-item label="所在地">
                        <el-input v-model="userInfo.Address"></el-input>
                    </el-form-item>
                    <el-form-item label="公司">
                        <el-input v-model="userInfo.Company"></el-input>
                    </el-form-item>
                    <el-form-item label="签名" v-if="isPCH5Text !== 'PC'">
                        <div @click="signVisible = true"
                            style="height: 100px;border: 1px solid #ccc;line-height: 100px;text-align: center;width: 100%;box-sizing: border-box;background-color: #fff;"
                            v-if="!userInfo.NameSignature">
                            点击预制签名
                        </div>
                        <!-- transform: rotate(-90deg); -->
                        <div style="display: flex;align-items: center;justify-content: center;height: 100px;text-align: center;box-sizing: border-box;overflow: hidden;width: 100%;height: 100px;border: 1px solid #ccc;"
                            @click="signVisible = true" v-if="userInfo.NameSignature">
                            <img :src="userInfo.NameSignature" alt=""
                                style="width: 100%;background: #fff;box-sizing: border-box;height: 100px;">
                        </div>

                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmitUserInfo" :loading="loading" style="width: 100%">保存
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="info" @click="logOut" :loading="loading" style="width: 100%">退出登录
                        </el-button>
                    </el-form-item>
                </el-form>

            </el-card>

        </div>

        <div v-if="signVisible" style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;z-index: 99;">
            <sign @close="signVisible = false" @success="signSub" :useSing="false"></sign>
        </div>
    </div>
</template>
<script>
import TokenCache from "@/utils/cache/TokenCache";
import sign from '../../components/Sign/sign.vue'

export default {
    data() {
        return {
            TokenCache,
            loading: false,
            userInfo: {
                Icon: '',
            },
            src: '',
            isPCH5Text: "",
            signVisible: false,
        };
    },
    props: {},
    components: {
        sign
    },
    methods: {
        getsignImg() {
            this.$http.post('/Base_ProjectManage/Project_ExternalUser/GetTextToImageForSign', {
                "Text": this.userInfo.RealName,
                "Width": this.userInfo.RealName.length ? Math.floor((window.innerHeight - 60) / this.userInfo.RealName.length) : 50,
                // "BackGroundColor": "#ffffff",
                // "FontFamily": "宋体",
                // "FontColor": "#D1100B",
                "FontWeight": true
            }).then(res => {
                if (res.Success) {
                    window.localStorage.setItem('signImg', res.Data)
                }
                console.log()
            })
        },
        logOut() {
            this.$router.push('/ExternalUserLogin')
        },
        onSubmitUserInfo() {
            if (!this.userInfo.RealName) {
                this.$phoneMessage({
                    message: '请填写姓名',
                })
                return
            }
            this.loading = true
            this.$http.post('/Base_ProjectManage/Project_ExternalUser/SaveDateForEUInfo', {
                ...this.userInfo
            }).then(res => {
                this.loading = false
                if (res.Success) {
                    this.$phoneMessage({
                        message: '保存成功',
                    })
                    this.getsignImg()
                    window.localStorage.setItem('EuInfo', JSON.stringify(this.userInfo))
                } else {
                    this.$phoneMessage({
                        message: res.Msg,
                    })
                }
            })
        },
        signSub(src) {
            this.userInfo.NameSignature = src
            this.signVisible = false
        },
        handleSuccess(res) {
            if (res.Code == 200) {
                this.userInfo.Icon = res.Url
            } else {
                this.$message.error(res.Message)
            }
        },
        beforeAvatarUpload(file) {
            console.log(file)
            const isJPG = file.type === "image/jpeg" || file.type === 'image/jpg' || file.type === "image/png";
            const isLt20M = file.size / 1024 / 1024 < 20;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 或者 PNG 格式!");
            }
            if (!isLt20M) {
                this.$message.error("上传头像图片大小不能超过 20MB!");
            }
            //   // 校验通过后显示裁剪框
            //   this.canCropper = isJPG && isLt20M
            return true
        },
        getSystemInfo() {
            if (/(micromessenger)/i.test(navigator.userAgent)) {
                return "微信"
            } else {
                // 判断h5还是pc true就是h5
                let client =
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    );
                if (client) {
                    return "H5"
                } else {
                    return "PC"
                }
            }
        },

    },
    mounted() { },
    created() {
        this.userInfo = JSON.parse(window.localStorage.getItem('EuInfo')) || {}

        // console.log(window.innerHeight - 60, '///')
        this.getsignImg()
        this.isPCH5Text = this.getSystemInfo()
        window.addEventListener('resize', () => {
            this.isPCH5Text = this.getSystemInfo()
        })

    },
    computed: {},
    watch: {},
};
</script>
<style lang='scss' scoped>
.ExternalUserInfoWrap {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #eccece;
}

.ExternalUserInfo {

    padding: 10px;

    box-sizing: border-box;

}

.ExternalUserInfoWrap_PC {
    width: 40vw;
    padding: 0 30vw;
    box-sizing: content-box;
    height: 100vh;
    overflow: hidden;
    background: #eccece;
}

.Shop_ApprovalInfoList_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100vw;
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
}

.Shop_ApprovalInfoList_bottom_PC {
    position: fixed;
    bottom: 0;
    left: calc(30vw + 10px);
    height: 60px;
    width: calc(40vw - 20px);
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
}
</style>